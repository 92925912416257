import React, { useEffect, useState } from "react";

import { getStorageLocation } from "../utils/scripts";
import { storage } from "../firebase";
import { ref, getDownloadURL } from "firebase/storage";

const useBackground = (user, classname) => {
  const [bg, setBg] = useState(undefined);

  useEffect(() => {
    const getBackground = async (data, name) => {
      try {
        const background = data.currentBackground;
        const unlockedBackgrounds = data.unlockedBackgrounds;
        const backgroundRef = ref(
          storage,
          getStorageLocation(unlockedBackgrounds[background])
        );
        const url = await getDownloadURL(backgroundRef);
        const image = <img className={name} src={url} alt="background" />;
        setBg(image);
      } catch (error) {
        console.log(error.code, error.message);
        return false;
      }
    };

    getBackground(user, classname);
  }, [user, classname]);

  return bg;
};

export default useBackground;
