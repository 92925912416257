import React, { useState, useEffect, useContext, useRef } from "react";
import { UserContext } from "../../utils";
import { useNavigate } from "react-router-dom";
import { login } from "../../utils/DAL";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Login = (props) => {
  const { setUser } = props;
  const userData = useContext(UserContext);
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(false);
  const loginUser = useRef({});
  const navigate = useNavigate();

  useEffect(() => {
    if (userData?.user) {
      navigate(`/${userData?.data?.pathname}`);
    }
  });

  const handleEmailChange = (e) => {
    loginUser.current.email = e.target.value;
  };
  const handlePasswordChange = (e) => {
    loginUser.current.password = e.target.value;
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const user = await login(
      loginUser.current.email,
      loginUser.current.password,
      setError
    );
    if (!error) {
      const userData = await setUser(user);
      if (userData) {
        navigate(`/${userData.data?.pathname}`);
      } else {
        console.log("error");
        setError(true);
      }
    }
    setDisabled(false);
  };

  const handleSignup = (e) => {
    e.preventDefault();
    navigate("/signup");
  };

  return (
    <div className="Login-wrapper">
      <div className="Login">
        <h3>Login</h3>
        <Form onSubmit={handleLogin} className="Login-form">
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              autoComplete="off"
              type="email"
              placeholder="Enter email"
              onChange={handleEmailChange}
            />
          </Form.Group>
          <Form.Group className="Login-input" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={handlePasswordChange}
            />
          </Form.Group>
          <Button
            variant="primary"
            type="submit"
            disabled={disabled}
            className="Login-button"
          >
            Log In
          </Button>
          <p className="Login-signup">
            Don't have a card?
            <Button variant="link" disabled={disabled} onClick={handleSignup}>
              Sign Up!
            </Button>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default Login;
