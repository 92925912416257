import React, { useState, useEffect, useContext } from "react";

import { Loading } from "../../components";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import useGetDoc from "../../hooks/useGetDoc";
import { UserContext } from "../../utils";
import { getStorageLocation } from "../../utils/scripts";
import { updateDoc } from "../../utils/DAL";
import { storage } from "../../firebase";
import { ref, getDownloadURL } from "firebase/storage";

const Backgrounds = () => {
  const [loading, setLoading] = useState(true);
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const availableBackgrounds = useGetDoc(
    "global",
    "assets"
  )?.availableBackgrounds;

  const [currentBgIndex, setCurrentBgIndex] = useState(0);
  const [backgrounds, setBackgrounds] = useState([]);

  const handleChangeCurrent = (newIndex) => {
    let oldBg = backgrounds[currentBgIndex];
    let newBg = backgrounds[newIndex];
    let bgCopy = [...backgrounds];
    oldBg = { ...oldBg, type: "unlocked" };
    newBg = { ...newBg, type: "current" };
    bgCopy[currentBgIndex] = oldBg;
    bgCopy[newIndex] = newBg;
    setBackgrounds(bgCopy);
    setCurrentBgIndex(newIndex);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    navigate(`/${user.data.pathname}`);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await updateDoc("users", user.user.uid, {
      currentBackground: backgrounds[currentBgIndex]?.title,
    });
    user.data.currentBackground = backgrounds[currentBgIndex]?.title;
    navigate(`/${user.data.pathname}`);
  };

  const bgDispatch = (bgData, index) => {
    switch (bgData?.type) {
      case "current":
        return (
          <div className="Background-image-container" key={bgData?.title}>
            <div className="Background-current">
              <img
                className="Background-img-current"
                src={bgData?.source}
                alt="Current Bg"
              />
            </div>
            {bgData?.title}
          </div>
        );
      case "unlocked":
        return (
          <div
            className="Background-image-container"
            key={bgData?.title}
            onClick={() => {
              handleChangeCurrent(index);
            }}
          >
            <div className="Background-other">
              <img
                className="Background-img"
                src={bgData?.source}
                alt="Unlocked Bg"
              />
            </div>
            {bgData?.title}
          </div>
        );
      default:
        return (
          <div className="Background-image-container" key={bgData?.title}>
            <div className="Background-other">
              <img
                className="Background-img"
                src={bgData?.source}
                alt="Locked Bg"
              />
            </div>
            {bgData?.title}
          </div>
        );
    }
  };

  useEffect(() => {
    let userUnlockedBgs = user.data.unlockedBackgrounds;
    let userCurrentBg = user.data.currentBackground;
    const loadCurrentBg = async () => {
      userCurrentBg = getStorageLocation(userUnlockedBgs[userCurrentBg]);
      userCurrentBg = ref(storage, userCurrentBg);
      userCurrentBg = await getDownloadURL(userCurrentBg);
      userCurrentBg = {
        source: userCurrentBg,
        type: "current",
        title: user.data.currentBackground,
      };

      return userCurrentBg;
    };

    const loadUnlockedBgs = async () => {
      let unlockedBgsArr = [];
      for (const [key, val] of Object.entries(userUnlockedBgs)) {
        if (key !== user.data.currentBackground) {
          let bg = getStorageLocation(val);
          bg = ref(storage, bg);
          bg = await getDownloadURL(bg);
          unlockedBgsArr.push({ source: bg, type: "unlocked", title: key });
        }
      }
      return unlockedBgsArr;
    };

    const loadLockedBgs = async () => {
      const unlockedKeys = Object.keys(user.data.unlockedBackgrounds);
      let lockedBgArr = [];
      if (availableBackgrounds) {
        for (const [key, val] of Object.entries(availableBackgrounds)) {
          if (!unlockedKeys.includes(key)) {
            let bg = getStorageLocation(val);
            bg = ref(storage, bg);
            bg = await getDownloadURL(bg);
            lockedBgArr.push({ source: bg, type: "locked", title: key });
          }
        }
      }

      return lockedBgArr;
    };

    const loadBgs = async () => {
      let currentBg = await loadCurrentBg();
      let unlockedBgs = await loadUnlockedBgs();
      let lockedBgs = await loadLockedBgs();
      setBackgrounds([currentBg, ...unlockedBgs, ...lockedBgs]);
      setLoading(false);
    };

    loadBgs();
  }, [
    availableBackgrounds,
    user.data.currentBackground,
    user.data.unlockedBackgrounds,
  ]);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="Background-gallery">
      {backgrounds.map((bg, i) => bgDispatch(bg, i))}
      <div className="Background-button-bar">
        <Button
          onClick={handleCancel}
          className="Background-cancel-button"
          variant="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          className="Background-save-button"
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Backgrounds;
