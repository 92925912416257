import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";

const useGetDoc = (collection, docName) => {
  const [document, setDocument] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const retrieveDoc = async () => {
      try {
        const docToRetrieve = doc(db, collection, docName);
        const retrievedDoc = await getDoc(docToRetrieve);
        setDocument(retrievedDoc.data());
      } catch (error) {
        setError(error);
      }
    };
    retrieveDoc();
  }, [collection, docName]);

  return document;
};

export default useGetDoc;
