import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { signup } from "../../utils/DAL";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Signup = (props) => {
  const { setUser } = props;
  const [error, setError] = useState(false);
  const userInfo = useRef({});
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    userInfo.current.email = e.target.value;
  };
  const handlePasswordChange = (e) => {
    userInfo.current.password = e.target.value;
  };
  const handlePathnameChange = (e) => {
    userInfo.current.pathname = e.target.value;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    navigate("/login");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }
    const user = await signup(
      userInfo.current.email,
      userInfo.current.password,
      userInfo.current.pathname,
      setError
    );
    if (user) {
      const userData = setUser(user);
      console.log(userData);
      if (userData) {
        navigate(`/${userData.pathname}`);
      }
    }
  };
  return (
    <div className="Login-wrapper">
      <div className="Login">
        <h3>Sign Up</h3>
        <Form
          noValidate
          validated={error}
          onSubmit={handleSubmit}
          className="Login-form"
        >
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              autoComplete="off"
              type="email"
              placeholder="Enter email"
              onChange={handleEmailChange}
              required
            />
            <Form.Control.Feedback type="invalid">
              Something went wrong with this email
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="Login-input" controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Password"
              onChange={handlePasswordChange}
              required
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="username">
            <Form.Label>Username</Form.Label>
            <Form.Control
              autoComplete="off"
              type="text"
              placeholder="Enter an optional username"
              onChange={handlePathnameChange}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid username
            </Form.Control.Feedback>
          </Form.Group>
          <Button variant="primary" type="submit" className="Login-button">
            Sign up
          </Button>
          <p className="Login-signup">
            Already have a card?
            <Button variant="link" onClick={handleLogin}>
              Log In!
            </Button>
          </p>
        </Form>
      </div>
    </div>
  );
};

export default Signup;
