import React, { useEffect, useState } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { Loading } from "./components";
import { Notecard, PrivateRoute, Login, Signup, Backgrounds } from "./routes";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { UserContext } from "./utils";
import { retrieveDoc } from "./utils/DAL";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const setUserData = async (user) => {
    try {
      const docSnap = await retrieveDoc("users", user.uid);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const userData = { user, data };
        setUser(userData);
        return userData;
      } else {
        throw new Error("Document does not exist");
      }
    } catch (error) {
      console.log(error.code, error.message);
      return false;
    }
  };

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userData = await setUserData(user);
        console.log("User is signed in: ", userData.user.uid);
      } else {
        console.log("User is signed out");
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="App">
        <div className="background">
          <Loading />
        </div>
      </div>
    );
  }
  return (
    <div className="App">
      <div className="background" />
      <UserContext.Provider value={user}>
        <Router>
          <Routes>
            <Route element={<Login setUser={setUserData} />} path="/login" />
            <Route element={<Signup setUser={setUserData} />} path="/signup" />
            <Route
              element={
                <PrivateRoute>
                  <Notecard setUser={setUser} />
                </PrivateRoute>
              }
              path="/*"
            />
            <Route
              element={
                <PrivateRoute>
                  <Backgrounds />
                </PrivateRoute>
              }
              path="/:pathname/backgrounds"
            />
            <Route
              element={
                <PrivateRoute>
                  <div>404</div>
                </PrivateRoute>
              }
              path="/*"
            />
          </Routes>
        </Router>
      </UserContext.Provider>
    </div>
  );
}

export default App;
