import * as emailValidator from "email-validator";
import constants from "../assets/constants.json";

const validateEmail = (email) => {
  return emailValidator.validate(email);
};

const getStorageLocation = (fileName) => {
  return `couplecard_backgrounds/${fileName}.png`;
};

const generateBgInfoForNewUser = () => {
  const unlockedBackgrounds = constants.defaultBackgrounds;
  const unlockedBackgroundsKeys = Object.keys(unlockedBackgrounds);
  const currentBackground =
    unlockedBackgroundsKeys[
      Math.floor(Math.random() * unlockedBackgroundsKeys.length)
    ];
  return { currentBackground, unlockedBackgrounds };
};

export { validateEmail, getStorageLocation, generateBgInfoForNewUser };
