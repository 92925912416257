import { doc, setDoc, getDoc } from "firebase/firestore";
import { db, storage, auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { ref, getDownloadURL } from "firebase/storage";

import {
  validateEmail,
  generateBgInfoForNewUser,
  getStorageLocation,
} from "./scripts";

const login = async (email, password, setError) => {
  try {
    console.log("Logging in...");
    const userCred = await signInWithEmailAndPassword(auth, email, password);
    const user = userCred.user;
    console.log("Logged in user with ID: ", user.uid);
    return user;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
    setError(errorMessage);
    return false;
  }
};

const signup = async (email, password, pathname, setError) => {
  try {
    console.log("Signing up...");
    if (!validateEmail(email)) {
      throw new Error("Invalid email");
    }
    const userCred = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCred.user;
    console.log("Created new account with ID: ", user.uid);
    console.log("Setting user data...");
    const bgInfo = generateBgInfoForNewUser();
    const data = {
      currentBackground: bgInfo.currentBackground,
      message: "Write your first message here!",
      pathname: `${pathname || user.uid}`,
      unlockedBackgrounds: bgInfo.unlockedBackgrounds,
    };
    console.log(data);
    await updateDoc("users", user.uid, data);
    return data;
  } catch (error) {
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
    setError(errorMessage);
    return false;
  }
};

const signout = async () => {
  try {
    await signOut(auth);
    return true;
  } catch (error) {
    console.log(error.code, error.message);
    return false;
  }
};

const getCurrentUser = () => {
  const user = auth.currentUser;
  return user;
};

const updateDoc = async (
  collection,
  docName,
  data,
  merge = { merge: false }
) => {
  const docToUpdate = doc(db, collection, docName);
  const newDoc = await setDoc(docToUpdate, data, { merge });
  return newDoc;
};

const retrieveDoc = async (collection, docName, setter) => {
  try {
    const docToRetrieve = doc(db, collection, docName);
    const retrievedDoc = await getDoc(docToRetrieve);
    if (setter) {
      setter(retrievedDoc);
    }
    return retrievedDoc;
  } catch (error) {
    console.log(error.line, error.code, error.message);
    return false;
  }
};

const getBackgroundUrls = async (data) => {
  try {
    const unlockedBackgrounds = data.unlockedBackgrounds;
    const backgrounds = [];
    for (const background in unlockedBackgrounds) {
      const backgroundRef = ref(
        storage,
        getStorageLocation(unlockedBackgrounds[background])
      );
      const url = await getDownloadURL(backgroundRef);
      backgrounds.push(url);
    }
    return backgrounds;
  } catch (error) {
    console.log(error.code, error.message);
    return false;
  }
};

export {
  login,
  signup,
  updateDoc,
  retrieveDoc,
  signout,
  getCurrentUser,
  getBackgroundUrls,
};
