import React, { useEffect, useState, useContext, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { updateDoc, signout } from "../../utils/DAL";
import { UserContext } from "../../utils";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Loading } from "../../components";
import useBackground from "../../hooks/useBackground";

const Notecard = (props) => {
  const { setUser } = props;
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const bg = useBackground(user.data, "Notecard-background");
  const layout = useRef({
    currentBg: user.data.currentBackground,
    message: user.data.message,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (bg) {
      setLoading(false);
    }
  }, [bg]);

  const handleEdit = async (e) => {
    e.preventDefault();
    if (isEditing) {
      try {
        setDisabled(true);
        const updatedDoc = await updateDoc(
          "users",
          user.user.uid,
          { message: layout.current.message },
          { merge: true }
        );
        setDisabled(false);
        console.log("updated document successfully: ", updatedDoc);
        user.data.message = layout.current.message;
        console.log(user.data);
      } catch (error) {
        console.log(error.code, error.message);
      }
    }
    setIsEditing(!isEditing);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleMessage = (e) => {
    layout.current.message = e.target.value;
  };

  const handleSignout = async (e) => {
    e.preventDefault();
    setDisabled(true);
    const signoutStatus = await signout();
    if (signoutStatus) {
      setUser({});
      setDisabled(false);
      navigate("/login");
    }
  };

  const handleNavigateBackground = async (e) => {
    e.preventDefault();
    navigate(`${window.location.pathname}/backgrounds`);
  };

  if (loading) {
    return <Loading />;
  }

  if (isEditing) {
    return (
      <div className="Notecard-wrapper">
        <Form className="Notecard-edit-wrapper" onSubmit={handleEdit}>
          <Form.Group className="Notecard-message" controlId="message">
            <div className="Notecard">
              {bg}
              <div className="Notecard-text">
                <Form.Control
                  autoComplete="off"
                  type="text"
                  onChange={handleMessage}
                />
              </div>
            </div>
          </Form.Group>
          <div>
            <Button
              className="Button"
              variant="secondary"
              onClick={handleCancel}
              disabled={disabled}
            >
              Cancel
            </Button>
            <Button
              className="Button"
              variant="primary"
              disabled={disabled}
              type="submit"
            >
              Update Message
            </Button>
          </div>
        </Form>
        <Button className="Button" variant="danger" onClick={handleSignout}>
          Sign Out
        </Button>
      </div>
    );
  }

  return (
    <div className="Notecard-wrapper">
      <div className="Notecard-message">
        <div className="Notecard" onClick={handleEdit}>
          {bg}
          <p className="Notecard-text">{layout.current.message}</p>
        </div>
      </div>

      <Button
        onClick={handleNavigateBackground}
        className="Button"
        disabled={disabled}
        variant="link"
      >
        Choose a different background
      </Button>
      <Button className="Button" variant="danger" onClick={handleSignout}>
        Sign Out
      </Button>
    </div>
  );
};

export default Notecard;
