import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../utils";

const PrivateRoute = (props) => {
  const { children } = props;
  const user = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !user.user) {
      navigate("/login");
    } else {
      const pathname = user?.data?.pathname;
      const currentPathname = window.location.pathname;
      const mainPathname = currentPathname.slice(1, pathname.length + 1);
      if (pathname !== mainPathname) {
        navigate(`/${pathname}`);
      } else if (!pathname) {
        navigate("/error");
      }
    }
  }, [user]);
  if (user?.user) {
    return children;
  }
};

export default PrivateRoute;
